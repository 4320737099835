

$(document).on('click', '.accord-header', function(){
    var elem = $(this),
        content = elem.next(),
        item = elem.parent(),
        siblings = item.siblings(),
        dropdowns = siblings.find('.accord-content');

    item.toggleClass('active');
    content.stop().slideToggle(300);

    siblings.removeClass('active');
    dropdowns.stop().slideUp(300);
});