$(document).ready(function () {
     
    $('.news-slider, .media-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        adaptiveHeight: true,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
        responsive: [{
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
            } 
        },
        {
            breakpoint: 545,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
    
    })

    $('.members-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 2,
        adaptiveHeight: true,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
        responsive:[
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                } 
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                } 
            },
        ]
    })

    $('.reviews-pod').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        variableWidth: true,
        centerMode: true,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
    })

    
    
    window.onload = function (){

        $('.chronicle-slider').on('init', function(){
            var chronicle_nav = new Swiper(".chronicle-nav", {
                direction: "vertical",
                slidesPerView: 1
            }).on('slideChange', function (swiper) {
                var index = swiper.realIndex;
                    active = $('.swiper-slide.year').eq(index).text(),
                    active = $('.slide[data-year="' + active + '"]').eq(0).attr('data-slick-index'),
                    active = parseInt(active);
        
                $('.chronicle-slider').slick('slickGoTo', active);
            }).on('click', function(swiper, event){
                var index = swiper.clickedIndex;
                    active = $('.swiper-slide.year').eq(index).text(),
                    active = $('.slide[data-year="' + active + '"]').eq(0).attr('data-slick-index'),
                    active = parseInt(active);
        
                $('.chronicle-slider').slick('slickGoTo', active);
            })
        
            $('.chronicle-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
                var active = $('.slide[data-slick-index="' + nextSlide + '"]'),
                    year = active.data('year'),
                    active = $('.year.swiper-slide[data-year="' + year + '"]');
        
                if(!active.hasClass('swiper-slide-active')){
                    var index = active.index();
        
                    chronicle_nav.slideTo(index)
                }
            })
        })
    
        $('.chronicle-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            adaptiveHeight: true,
            prevArrow: '<button class="icon-left prev"></button>',
            nextArrow: '<button class="icon-right next"></button>'
        })

    }

    $('.popup-images-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        adaptiveHeight: true,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>'
    })

    $('.advantages-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        adaptiveHeight: true,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
        responsive:[
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            } 
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            } 
        },
        ]
    })

    $('.gallery-grid .item-photo').click(function(){
        var elem = $(this),
            index = elem.index(),
            video = elem.attr('data-video'),
            slider = $('.popup-images-slider'),
            popup = $('.slider-popup');

        if(video){
            video = video.replace('/', '');

            if(video.indexOf('=')){
                video = video.split('=');
                video = video[1];
            }
            player.loadVideoById(video)

            popup = $('.video-popup');
            popup.fadeIn(300);
        }else{
            popup.fadeIn(300);
            slider.slick('setPosition');
            slider.slick('slickGoTo', index);
        }
    })

    $('.close-slider-popup').click(function(){
        $('.slider-popup').fadeOut(300);
    })

    $('.close-video-popup').click(function(){
        $('.video-popup').fadeOut(300);
        stopVideo()
    })

    $('.single-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        infinite: false,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
    })

   
    $('.gallery-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        adaptiveHeight: true,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
    })

    $('.about-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
    })

    $('.announcement-wrap').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 1800,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
    })

    $('.lic-slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        adaptiveHeight: true,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
        responsive:[
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                } 
            },
        ]
    })

    $('.def-slider .slider-content').slick({
        infinite: true,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
        adaptiveHeight: true
    })
    
});

$(document).ready(function() {
    $(document).on('click', '.slider-pod-h', function() {
        $('.reviews-pod').slick('setPosition')
    })

    $(document).on('click', '.gallery', function() {
        $('.single-slider').slick('setPosition')
    })
})
    
    