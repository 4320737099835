$('.archive-city').click(function(){
    var elem = $(this),
        tax = elem.data('tax'),
        slug = elem.data('slug'),
        result = {tax, slug},
        archive = $('.archive'),
        paste = $('.archive-grid .line');

    archive.addClass('loading');

    requestAjax('get_archive_items', result, function(res){
        archive.removeClass('loading');
        paste.html(res);
    })
})