var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var player;
function onYouTubeIframeAPIReady() {
    player = new YT.Player('player')

    let players = $('.yt-video')

    if(players.length) {
        players.each(function(){
            let item = $(this)
            let id = item.data('id')

            new YT.Player(item[0], {
                videoId: id
            })
        })
    }

}

function stopVideo() {
    player.stopVideo();
}

function playVideo() {
    player.playVideo();
}