
if($('#branch-map').length > 0) {    
    ymaps.ready(init);

}

function init() {
    var myMap = new ymaps.Map('branch-map', {
            center: [76.91612604467768,43.24468006977554],
            zoom: 13,
            controls: ['geolocationControl', 'searchControl']
        })

}