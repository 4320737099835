$(document).ready(function () {

    setTimeout(function(){
        function animateSections() {
            var sections = $('section:not(.animate)'),
                scroll = $(window).scrollTop();
    
            sections.each(function () {
                var elem = $(this),
                    height = $(window).height() * 0.4,
                    offset = elem.offset().top - height,
                    child = elem.data('animate'),
                    delay = elem.data('delay') ?? 100;
    
                if (offset < scroll) {
                    elem.addClass('animate');
    
                    if (child) {
                        var items = elem.find('.' + child),
                            timer = delay;
    
                        items.each(function () {
                            var item = $(this);
    
                            setTimeout(function () {
                                item.addClass('animate');
                            }, timer);
    
                            timer += delay;
    
                        });
    
                    }
    
                }
    
            });
    
        }
    
        $(window).scroll(animateSections);
        animateSections();
    },200)

});