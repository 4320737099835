// $('a[href^="#"]').on('click', function(event) {
//     event.preventDefault()
    
//     var sc = $(this).attr("href"),
//         dn = $(sc).offset().top - $('header.site-header').height()
//     $('html, body').animate({scrollTop: dn}, 700)
// })

$('.to-up').click(function(){
    $('html, body').stop().animate({scrollTop: 0}, 700)
})