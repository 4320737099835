function headerScroll(){
    if($(window).scrollTop()){
        $('header.site-header').addClass('scroll')
        $('.to-up').addClass('show')
    }else{
        $('header.site-header').removeClass('scroll')
        $('.to-up').removeClass('show')
    }
}

headerScroll()

$(window).scroll(headerScroll);

$(document).on('click', '.open-menu', function(){
    $('.mobile-menu').addClass('show')
})

$(document).on('click', '.close-menu', function(){
    $('.mobile-menu').removeClass('show')
})

$(document).click(function (e) {
    var menu = $('.menu-group')

    if ( !menu.is(e.target) && menu.has(e.target).length === 0) {
        $('.mobile-menu').removeClass('show')
    }
})

$(document).on('click', '.dropdown-toggler', function(e){
    var elem = $(this),
        dropdown = elem.find('.def-dropdown');

    e.preventDefault();
    dropdown.toggleClass('show');

    $(document).on('click', function (e){
        if (!elem.is(e.target) && elem.has(e.target).length === 0) {
            dropdown.removeClass('show');
        }
    });

})

$(document).on('click', '.select-city li, .select-city-popup .select-list li', function(){
    var elem = $(this),
        id = elem.data('id');

    elem.parents('.select-city-popup').addClass('loading');

    requestAjax('change_city', id, function(){
        window.location.reload();
    })
})

$(document).on('click', '.search-btn', function(){
    $('.search-wrapper').stop().slideToggle(300);
}) 

$(document).on('mouseenter', '.select-city-popup .select-list li', function(){
    var elem = $(this),
        id = elem.data('id'),
        image = $('.select-city-popup .image.' + id);

    image.addClass('active');
    image.siblings().removeClass('active');
})

$(document).on('mouseleave', '.select-city-popup .select-list', function(){
    var images = $('.select-city-popup .image');

    images.removeClass('active');
})


$(document).on('click', '.toggle-menu', function(){
    $('.mobile-menu').stop().fadeToggle(300)
})

$(document).on('click', '.close-menu', function(){
    $('.mobile-menu').stop().fadeOut(300)
})

$('.mobile-menu .menu-item-has-children > a').click(function(){
    var elem = $(this);
    elem.next('.sub-menu').stop().slideToggle(300);
});


